
<template>
  <div class="app-container">
    <div class="banner"></div>
    <div class="content">
      <div class="intro">
        <div class="title">{{ pageInfo.title }}</div>
        <div class="divide"></div>
        <div class="desc">{{ pageInfo.desc }}</div>
        <img :src="pageInfo.mainPic" alt="" class="main-pic" />
        <div class="design-title">设计原理</div>
        <div class="design-decoration"></div>
        <div class="design-desc">{{ pageInfo.design }}</div>

        <div class="tab-container">
          <el-tabs tab-position="left" class="tabs">
            <el-tab-pane
              v-for="item in functionsList"
              :key="item.title"
              :label="item.title"
            >
              <div class="tab-detail">
                <div
                  class="detail-item"
                  v-for="sub in item.children"
                  :key="sub.img"
                >
                  <img :src="sub.img" alt="" class="item-img" />
                  <div class="item-title">{{ sub.title }}</div>
                </div>
              </div>
            </el-tab-pane>
          </el-tabs>
        </div>

        <el-button>免费试用</el-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      pageInfo: {
        title: "智慧养殖",
        desc: "整个系统的建设目标分为三个阶段：数据采集自动化阶段、混样自动化阶段、全链路的 PCR 数 据智能管理平台。数据采集自动化阶段 实现样本采样过程自动化，无纸化，样本检验结果数据同步自动化，建立样本大数据基础设施。混样自动化阶段解决传统样本人工混样问题，提高检测能力水平。全链路的 PCR 数据智能管理平台 将扫码系统、采样管、混样仪器、提取仪、荧光定量 PCR 仪全部接入数据系统，实现从采样到出结果全流程监控的智能管理平台。",
        mainPic: require("../../assets/solutions/cultivation/main.png"),
        design:
          "文字说明文字说明文字说明文字说明文字说明文字说明文字说明文字说明文字说明文字说明文字说明文字说明文字说明文字说明文字说明文字说明文字说明文字说明文字说明文字说明文字说明文字说明文字说明文字说明文字说明文字说明文字说明文字说明文字说明文字说明文字说明文字说明文字说明文字说明文字说明文字说明文字说明文字说明文字说明",
      },
      functionsList: [
        {
          title: "采样录入",
          children: [
            {
              title: "样本管条形码",
              img: require("../../assets/solutions/cultivation/1-1.png"),
            },
            {
              title: "栏位二维码",
              img: require("../../assets/solutions/cultivation/1-2.png"),
            },
            {
              title: "信息确认录入",
              img: require("../../assets/solutions/cultivation/1-3.png"),
            },
          ],
        },
        {
          title: "样本信息",
          children: [
            {
              title: "样本信息同步更新",
              img: require("../../assets/solutions/cultivation/2-1.png"),
            },
            {
              title: "样本状态更新",
              img: require("../../assets/solutions/cultivation/2-2.png"),
            },
          ],
        },
        {
          title: "任务",
          children: [
            {
              title: "任务分配",
              img: require("../../assets/solutions/cultivation/3-1.png"),
            },
            {
              title: "信息查询",
              img: require("../../assets/solutions/cultivation/2-2.png"),
            },
            {
              title: "任务下达热图",
              img: require("../../assets/solutions/cultivation/3-3.png"),
            },
            {
              title: "完成结果分析",
              img: require("../../assets/solutions/cultivation/3-4.png"),
            },
          ],
        },
        {
          title: "人员",
          children: [
            {
              title: "信息管理",
              img: require("../../assets/solutions/cultivation/4-1.png"),
            },
            {
              title: "部门管理",
              img: require("../../assets/solutions/cultivation/4-2.png"),
            },
          ],
        },
        {
          title: "猪舍",
          children: [
            {
              title: "猪舍信息管理",
              img: require("../../assets/solutions/cultivation/5-1.png"),
            },
            {
              title: "猪舍结构管理",
              img: require("../../assets/solutions/cultivation/5-2.png"),
            },
            {
              title: "猪品种管理",
              img: require("../../assets/solutions/cultivation/5-3.png"),
            },
          ],
        },
        {
          title: "二维码",
          children: [
            {
              title: "生成二维码",
              img: require("../../assets/solutions/cultivation/6-1.png"),
            },
            {
              title: "打印二维码",
              img: require("../../assets/solutions/cultivation/6-2.png"),
            },
          ],
        },
        {
          title: "采样结果",
          children: [
            {
              title: "样本状态",
              img: require("../../assets/solutions/cultivation/7-1.png"),
            },
            {
              title: "检测结果",
              img: require("../../assets/solutions/cultivation/7-2.png"),
            },
          ],
        },
        {
          title: "采样分析",
          children: [
            {
              title: "采样样本分析",
              img: require("../../assets/solutions/cultivation/8-1.png"),
            },
            {
              title: "采样结果分析",
              img: require("../../assets/solutions/cultivation/8-2.png"),
            },
          ],
        },
        {
          title: "异常预警",
          children: [
            {
              title: "实时异常地图",
              img: require("../../assets/solutions/cultivation/9-1.png"),
            },
            {
              title: "异常品种公布",
              img: require("../../assets/solutions/cultivation/9-2.png"),
            },
          ],
        },
        {
          title: "仓储",
          children: [
            {
              title: "采购管理",
              img: require("../../assets/solutions/cultivation/10-1.png"),
            },
            {
              title: "出入库管理",
              img: require("../../assets/solutions/cultivation/10-2.png"),
            },
          ],
        },
        {
          title: "数据报表",
          children: [
            {
              title: "账单详情",
              img: require("../../assets/solutions/cultivation/11-1.png"),
            },
            {
              title: "结算情况",
              img: require("../../assets/solutions/cultivation/11-2.png"),
            },
            {
              title: "年度账单趋势",
              img: require("../../assets/solutions/cultivation/11-3.png"),
            },
          ],
        },
        {
          title: "可视化系统",
          children: [
            {
              title: "电视大屏",
              img: require("../../assets/solutions/cultivation/12-1.png"),
            },
          ],
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
@function vw($px) {
  @return ($px / 1920) * 100vw;
}

.app-container {
  .banner {
    height: vw(516);
    width: 100%;
    background: url("~@/assets/solutions/cultivation/banner.png") no-repeat center;
    background-size: cover;
  }

  .content {
    margin-top: vw(80);
    margin-bottom: vw(119);
    padding: 0 vw(369);
    display: flex;
    flex-direction: column;
    .intro {
      display: flex;
      flex-direction: column;
      align-items: center;
      .title {
        font-size: vw(24);
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #333333;
        align-self: flex-start;
        margin-bottom: vw(30);
      }
      .divide {
        width: vw(1180);
        height: 1px;
        background: #dadada;
        margin-bottom: vw(32);
      }
      .desc {
        font-size: vw(14);
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #333333;
        text-align: start;
        line-height: vw(30);
        margin-bottom: vw(30);
      }
      .main-pic {
        width: vw(680);
        height: vw(373);
        margin-bottom: vw(60);
      }
      .design-title {
        font-size: vw(16);
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #333333;
        margin-bottom: vw(19);
      }
      .design-decoration {
        width: vw(34);
        height: vw(2);
        background: #ff9a4f;
        border-radius: 1px;
        align-self: center;
        margin-bottom: vw(39);
      }
      .design-desc {
        font-size: vw(14);
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #333333;
        line-height: vw(30);
        text-align: start;
        margin-bottom: vw(102);
      }
      .tab-container {
        width: vw(1180);
        box-shadow: 0px 0px 20px 0px rgba(58, 54, 54, 0.16);
        border-radius: vw(14);
        margin-bottom: vw(60);

        .tabs {
          padding: vw(50);
          .tab-detail {
            display: flex;
            flex-wrap: wrap;
            .detail-item {
              display: flex;
              flex-direction: column;
              align-items: center;
              margin-left: vw(80);
              margin-bottom: vw(30);
              .item-img {
                width: vw(96);
                height: vw(96);
                margin-bottom: vw(19);
              }

              .item-title {
                font-size: vw(14);
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #333333;
              }
            }
          }
        }
      }

      ::v-deep .el-tabs__item {
        text-align: left;
        font-weight: bold;
        &:hover{
            color: rgba(0, 197, 28, 1);
        }
      }

      ::v-deep .el-tabs__item.is-active {
        color: rgba(0, 197, 28, 1);
      }

      ::v-deep .el-tabs__active-bar {
        background-color: rgba(0, 197, 28, 1);
      }

      ::v-deep .el-button {
        border: 1px solid #ff8225;
        color: #ff8225;
        background: #fff;
        &:hover {
          background: #ff8225;
          color: #fff;
        }
      }
    }
  }
}
</style>